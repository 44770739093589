import { toArray } from './utils';

class Accordion {
  constructor() {
    this.elements = [];
    this.buttons = [];
    this.contents = [];
    this.clickListeners = [];
    this.transitionListeners = [];

    this.reset();
  }

  reset() {
    const elements = toArray(
      document.querySelectorAll('.js-accordion-element')
    );
    const buttons = toArray(document.querySelectorAll('.js-accordion-button'));
    const contents = toArray(
      document.querySelectorAll('.js-accordion-content')
    );

    if (
      !(elements.length == buttons.length && elements.length == contents.length)
    ) {
      this.elements = this.buttons = this.contents = [];
      return;
    }

    this.clickListeners.forEach(l =>
      l[0].removeEventListener('click', l[1], false)
    );
    this.transitionListeners.forEach(l =>
      l[0].removeEventListener('transitionend', l[1], false)
    );

    this.elements = elements;
    this.buttons = buttons;
    this.contents = contents;
    this.clickListeners = [];
    this.transitionListeners = [];

    this.setup();
  }

  setup() {
    this.elements.forEach((el, index) => {
      const collapseOther = el.classList.contains(
        'js-accordion-collapse-other'
      );
      const toggleListener = () => this.toggleElement(index, collapseOther);
      this.buttons[index].addEventListener('click', toggleListener, false);
      this.clickListeners.push([this.buttons[index], toggleListener]);

      const transitionListener = () => {
        el.classList.remove('animating');
        this.contents[index].style.height = '';
      };
      this.contents[index].addEventListener(
        'transitionend',
        transitionListener,
        false
      );
      this.transitionListeners.push([this.contents[index], transitionListener]);
    });
  }

  toggleElement(index, collapseOther) {
    const isOpen = this.elements[index].classList.contains('is-open');

    const element = this.elements[index];
    const content = this.contents[index];
    const button = this.buttons[index];

    if (!isOpen) {
      if (collapseOther) {
        this.collapseSiblings(element);
      }
      element.classList.add('animating');
      content.style.height = content.scrollHeight + 'px';
      button.setAttribute('aria-expanded', 'true');
      content.setAttribute('aria-hidden', 'false');
    } else {
      content.style.height = content.scrollHeight + 'px';
      element.classList.add('animating');
      window.getComputedStyle(content).transform;
      content.style.height = '';
      button.setAttribute('aria-expanded', 'false');
      content.setAttribute('aria-hidden', 'true');
    }
    element.classList.toggle('is-open');
  }

  collapseSiblings(el) {
    const siblings = toArray(el.parentNode.children);
    siblings.forEach(sibling => {
      if (
        sibling != el &&
        sibling.classList.contains('js-accordion-element') &&
        sibling.classList.contains('is-open')
      ) {
        const index = this.elements.indexOf(sibling);
        if (index != -1) {
          this.toggleElement(index);
        }
      }
    });
  }
}

export default Accordion;
