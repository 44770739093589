import { toArray } from './utils';

class MenuMobile {
  constructor() {
    this.menuTogglers = toArray(
      document.querySelectorAll('.js-menu-mobile-toggle')
    );
    this.menuNotify = toArray(
      document.querySelectorAll('.js-menu-mobile-notify')
    );

    this.setup();
  }

  setup() {
    this.menuTogglers.forEach(btn =>
      btn.addEventListener('click', () => this.toggle())
    );
  }

  toggle() {
    this.menuNotify.forEach(el => el.classList.toggle('is-mobile-menu-open'));
  }
}

export default MenuMobile;
