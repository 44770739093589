import { toArray } from './utils';

class Tabs {
  constructor() {
    this.tabs = toArray(document.querySelectorAll('.js-tab'));
    this.tabLinks = toArray(document.querySelectorAll('.js-tab-link'));
    this.tabPanels = toArray(document.querySelectorAll('.js-tab-panel'));
    this.activeIndex = 0;

    if (
      !(
        this.tabs.length &&
        this.tabs.length == this.tabLinks.length &&
        this.tabs.length == this.tabPanels.length
      )
    ) {
      return;
    }

    this.setup();
  }

  setup() {
    this.tabLinks.forEach((link, index) => {
      link.addEventListener('click', () => this.activate(index));
    });
  }

  activate(index) {
    this.tabPanels[this.activeIndex].hidden = true;
    this.tabs[this.activeIndex].setAttribute('aria-selected', 'false');

    this.tabPanels[index].hidden = false;
    this.tabs[index].setAttribute('aria-selected', 'true');

    this.activeIndex = index;
  }
}

export default Tabs;
