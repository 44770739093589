import { toArray } from './utils';

const INPUT_MIN = 1;
const INPUT_MAX = 99;
const jQuery = window.jQuery;

class AddToCart {
  constructor() {
    this.input = toArray(document.querySelectorAll('.js-add-input'));
    this.add = toArray(document.querySelectorAll('.js-add-add'));
    this.increase = toArray(document.querySelectorAll('.js-add-increase'));
    this.decrease = toArray(document.querySelectorAll('.js-add-decrease'));

    if (
      !(
        this.add.length == this.input.length &&
        this.add.length == this.increase.length &&
        this.add.length == this.decrease.length
      )
    ) {
      return;
    }

    this.setup();
  }

  setup() {
    this.increase.forEach((el, index) =>
      el.addEventListener('click', () => this.changeValue(index, 1))
    );
    this.decrease.forEach((el, index) =>
      el.addEventListener('click', () => this.changeValue(index, -1))
    );
    this.add.forEach((el, index) =>
      el.addEventListener('click', () => this.setData(index))
    );

    if (jQuery) {
      jQuery(
        document.body
      ).on('added_to_cart', (event, fragments, hash, buttons) => {
        jQuery(buttons)
          .closest('.js-add')
          .addClass('added');
      });
    }
  }

  changeValue(index, difference) {
    let newVal = parseInt(this.input[index].value) + difference || 1;
    if (newVal < INPUT_MIN) {
      newVal = INPUT_MIN;
    }
    if (newVal > INPUT_MAX) {
      newVal = INPUT_MAX;
    }

    this.input[index].value = newVal;
  }

  setData(index) {
    this.add[index].dataset.quantity = this.input[index].value;
  }
}

export default AddToCart;
