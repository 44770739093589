/*
  Project: Public Self Storage
  Author: Xfive
 */

import 'wicg-focus-ring';
import 'svgxuse';

import MenuFull from './MenuFull';
import MenuMoble from './MenuMobile';
import Accordion from './Accordion';
import Tabs from './Tabs';
import AddToCart from './AddToCart';
import Scroll from './Scroll';
import ContactForm from './ContactForm';
import FormEvents from './FormEvents';

new MenuFull();
new MenuMoble();
new Accordion();
new Tabs();
new AddToCart();
new Scroll();
new ContactForm();
new FormEvents();
