import { toArray } from './utils';

const MOUSE_CLICK_COLLISION_DIFF_MS = 500;

class MenuFull {
  constructor() {
    this.menuButtons = toArray(
      document.querySelectorAll('.js-menufull-trigger')
    );
    this.lastOver = null;

    this.setupListeners();
  }

  setupListeners() {
    this.menuButtons.forEach(el => {
      const next = el.nextElementSibling;
      const parent = el.parentNode;
      if (!(next && next.classList.contains('js-menufull-submenu'))) {
        return;
      }
      el.addEventListener('mouseover', e => {
        parent.classList.add('is-open');
        this.lastOver = e;
      });
      el.addEventListener('mouseout', () => {
        parent.classList.remove('is-open');
      });
      next.addEventListener('mouseover', () => {
        parent.classList.add('is-open');
      });
      next.addEventListener('mouseout', () => {
        parent.classList.remove('is-open');
      });
      el.addEventListener('click', e => {
        if (
          this.lastOver &&
          e.target == this.lastOver.target &&
          e.timeStamp - this.lastOver.timeStamp < MOUSE_CLICK_COLLISION_DIFF_MS
        ) {
          return;
        }
        parent.classList.toggle('is-open');
        const first = next.querySelector('a, button');
        if (first && parent.classList.contains('is-open')) {
          first.focus();
        }
      });
    });
  }
}

export default MenuFull;
