export default class ContactForm {
  constructor() {
    this.contactForm = document.querySelector('.wpcf7');

    if (!this.contactForm) {
      return;
    }

    const formLocatizationSource = this.contactForm.querySelector(
      'input[name="get-localization"]'
    );
    const formLocalizationTarget = this.contactForm.querySelector(
      '.js-contact-form-localization'
    );

    if (
      formLocatizationSource &&
      formLocatizationSource.value &&
      formLocalizationTarget
    ) {
      formLocalizationTarget.textContent = formLocatizationSource.value;
    }

    this.setup();
  }

  setup() {
    this.contactForm.addEventListener('wpcf7beforesubmit', () => {
      this.contactForm.classList.add('x5-is-submitting');
    });
    this.contactForm.addEventListener('wpcf7submit', () => {
      this.contactForm.classList.remove('x5-is-submitting');
    });
  }
}
