export default class FormEvents {
  constructor() {
    this.setupGravityForm();
    this.setupWPCF();
  }

  sendEvent(category, action) {
    if (window.__gaTracker) {
      window.__gaTracker('send', 'event', category, action);
    }
  }

  setupGravityForm() {
    if (!window.jQuery) {
      return;
    }
    window.jQuery(document).bind('gform_confirmation_loaded', () => {
      this.sendEvent('Contact Form', 'submit');
    });
  }

  setupWPCF() {
    const form = document.querySelector('.wpcf7');
    if (!form) {
      return;
    }
    form.addEventListener('wpcf7mailsent', () => {
      this.sendEvent('Reservation Form', 'submit');
    });
  }
}
